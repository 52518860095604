<div class="header py-7 py-lg-6">
    <div id="maintenace-page">

        <h1 class="mt-7">
            <i class="fas fa-exclamation-triangle text-yellow"></i>
            <br>
            MANUTENÇÃO
        </h1>

        <br>
        <h3> Olá investidor, </h3>
        <h3> Estamos em manutenção.</h3>

        <br>
        <h3> Voltamos em breve! </h3>

    </div>
</div>