/* src/app/pages/maintenance/maintenance.component.scss */
#maintenace-page {
  text-align: center;
}
h1 {
  color: #8898aa;
}
h3 {
  color: #8898aa;
}
/*# sourceMappingURL=maintenance.component.css.map */
