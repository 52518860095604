import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: false
})
export class MaintenanceComponent implements OnInit {

  constructor(
    private _spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this._spinnerService.hide()
  }

}
